import { css } from '@emotion/react';

const mapViewStyles = {
  background: 'white',
  borderRadius: '3px',
  padding: '0px',
  border: '2px solid rgba(0, 0, 0, 0.2)',
  backgroundClip: 'padding-box',
  width: '30px',
  height: '30px',
};

const mapViewButtonContainer = {
  position: 'relative',
  zIndex: '800',
  float: 'right',
  padding: '8px 30px',
  width: '30px',
  height: '30px',
  lineHeight: '35px',
  display: 'block',
  textAlign: 'center',
};

const mapZoomButtonContainer = {
  position: 'relative',
  zIndex: '800',
  float: 'left',
  padding: '8px 23px',
  width: '30px',
  height: '30px',
  lineHeight: '35px',
  display: 'block',
  textAlign: 'center',
};

const ZoomControls = css`
  .leaflet-top {
  top 20vh;
  }
`;

export default {
  mapViewStyles,
  mapViewButtonContainer,
  mapZoomButtonContainer,
  ZoomControls,
};
