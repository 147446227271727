import React from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import IconButton from '@material-ui/core/IconButton';
import styles from './MapView.styles';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Replay from '@material-ui/icons/Replay';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/dist/styles.css';

const { mapViewStyles } = styles;

function ZoomControlWithReset(props) {
  const { bounds, fullscreen = true } = props;

  const map = useMap();
  //   mapZoomButtonContainer
  return (
    <>
      <IconButton
        style={mapViewStyles}
        onClick={e => {
          map.zoomIn();
          e.preventDefault();
        }}
        edge="start"
      >
        <Add fontSize="small" style={{ color: 'black' }} />
      </IconButton>
      <IconButton
        style={mapViewStyles}
        onClick={e => {
          map.zoomOut();
          e.preventDefault();
        }}
        edge="start"
      >
        <Remove fontSize="small" style={{ color: 'black' }} />
      </IconButton>
      <IconButton
        style={mapViewStyles}
        onClick={e => {
          map.fitBounds(bounds);
          e.preventDefault();
        }}
        edge="start"
      >
        <Replay fontSize="small" style={{ color: 'black' }} />
      </IconButton>
      {fullscreen && <FullscreenControl position="topleft" />}
    </>
  );
}

ZoomControlWithReset.propTypes = {
  zoomInTitle: PropTypes.string,
  zoomOutTitle: PropTypes.string,
  zoomResetTitle: PropTypes.string,
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

ZoomControlWithReset.defaultProps = {
  zoomInTitle: 'Zoom in',
  zoomOutTitle: 'Zoom out',
  zoomResetTitle: 'Reset zoom',
};

export default ZoomControlWithReset;
