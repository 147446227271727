import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Public from '@material-ui/icons/Public';
import Traffic from '@material-ui/icons/Traffic';
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet';
import Leaflet from 'leaflet';
import './leaflet.css';
import Constants from '../../../static/Constants';
import styles from './MapParallax.styles';
import getMapUrl from '../../shared/getMapUrl';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ZoomControlWithReset from './ZoomControlWithReset';

if (Leaflet.Icon) {
  Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';

  delete Leaflet.Icon.Default.prototype._getIconUrl;

  Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: '/marker_icons/partnerPin-2x.png',
    iconUrl: '/marker_icons/partnerPin-2x.png',
    shadowUrl: '/marker_icons/marker-shadow.png',
  });
}

const {
  mapViewStyles,
  mapViewButtonContainer,
  mapZoomButtonContainer,
} = styles;

const MapParallax = ({ projects }) => {
  const [view, setView] = useState('streets');
  const [url, setUrl] = useState(getMapUrl(Constants[view]));
  const [mapCenterCoordinates, setMapCenterCoordinates] = useState([0, 0]);

  const mapRef = useRef(null);

  const changeViewToggle = view => () => {
    setView(view);
    setUrl(getMapUrl(Constants[view]));
  };

  if (Leaflet && Leaflet.latLng && Leaflet.latLngBounds) {
    const corner1 = Leaflet.latLng(-90, -200);
    const corner2 = Leaflet.latLng(90, 220);
    var bounds = Leaflet.latLngBounds(corner1, corner2);
  }

  if (Leaflet && Leaflet.icon) {
    var markerDefault = Leaflet.icon({
      iconRetinaUrl: '/marker_icons/images/marker-icon-2x.png',
      iconUrl: '/marker_icons/marker-icon.png',
      shadowUrl: '/marker_icons/marker-shadow.png',
    });

    var markerSEZ = Leaflet.icon({
      iconUrl: '/marker_icons/markerSEZ.png',
      iconAnchor: [15, 50], // point of the icon which will correspond to marker's location
      popupAnchor: [3, -45], // same for popup
    });

    var markerProject = Leaflet.icon({
      iconUrl: '/marker_icons/markerProject.png',
      iconAnchor: [15, 50], // point of the icon which will correspond to marker's location
      popupAnchor: [3, -45], // same for popup
    });

    var markerPartner = Leaflet.icon({
      iconUrl: '/marker_icons/markerIPA.png',
      iconAnchor: [15, 50], // point of the icon which will correspond to marker's location
      popupAnchor: [3, -45], // same for popup
    });
  }

  const getMarker = key => {
    const markers = {
      PROJECT: markerProject,
      PARTNER: markerPartner,
      SEZ: markerSEZ,
    };
    return markers[key.toUpperCase()] || markerDefault;
  };

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (projects.length > 0) {
      setMapCenterCoordinates(projects[0]?.coordinates);
    }
  }, [projects]);

  function SetViewOnClick({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
  }

  const tileRef = useRef();
  useEffect(() => {
    if (tileRef) {
      tileRef?.current?.setUrl(url);
    }
  }, [url]);

  return (
    <div className="projects-map-container">
      {typeof window !== 'undefined' && (
        <MapContainer
          ref={mapRef}
          className="map"
          center={mapCenterCoordinates}
          zoom={15}
          zoomControl={false}
          zoomDelta={1}
          minZoom={2}
          maxBounds={bounds}
          maxBoundsViscosity={1.0}
          // scrollWheelZoom={false}
          dragging={mobileScreen ? false : true}
        >
          <TileLayer url={url} ref={tileRef} />

          {projects &&
            (projects || []).map(project => {
              return project.coordinates ? (
                <Marker
                  key={project.id}
                  position={project.coordinates}
                  icon={getMarker(project.currentPage || '')}
                >
                  <Tooltip>{project.regionName}</Tooltip>
                </Marker>
              ) : null;
            })}
          <div style={mapZoomButtonContainer}>
            <ZoomControlWithReset bounds={bounds} fullscreen={false} />
          </div>

          <div id={'streetIcon'} style={mapViewButtonContainer}>
            <IconButton
              style={mapViewStyles}
              onClick={changeViewToggle('streets')}
              edge="start"
              color={view === 'streets' ? 'primary' : 'default'}
            >
              <Traffic />
            </IconButton>
            <IconButton
              style={mapViewStyles}
              onClick={changeViewToggle('satellite')}
              edge="start"
              color={view === 'satellite' ? 'primary' : 'default'}
            >
              <Public />
            </IconButton>
          </div>
          <SetViewOnClick coords={mapCenterCoordinates} />
        </MapContainer>
      )}
    </div>
  );
};

MapParallax.propTypes = {
  projects: PropTypes.any,
};

export default MapParallax;
