import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { create } from 'apisauce';
import Constants from '../../../static/Constants';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { ThreeDots } from 'react-loader-spinner';
import { GOOGLE_CAPTCHA_V2_KEY } from '../../shared/constants';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });

const ChatPopup = ({ contactUserId, projectId, closeForm }) => {
  let captchaKey = GOOGLE_CAPTCHA_V2_KEY;
  const [countries, setCountries] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    apiSauceInstance
      .get(Constants.country + `/${i18n.language}`)
      .then(response => {
        setCountries(response.data);
      });
  }, [i18n.language]);

  const [email, setEmail] = useState('');
  const [firstName, setName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [country, setCountry] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [message, setMessage] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    secondName: '',
    email: '',
    country: '',
    company: '',
    designation: '',
    message: '',
  });
  const [isCaptchaPassed, setIsCaptchaPassed] = useState('');
  const [projectContactFormLoader, setProjectContactFormLoader] = useState(
    false
  );

  const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validateForm = errors => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));
    return valid;
  };

  const validateFormFields = errors => {
    let valid = true;
    Object.values(errors).forEach(val => val.length < 1 && (valid = false));
    return valid;
  };

  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let errorStrings = errors;

    switch (name) {
      case 'firstName':
        errorStrings.firstName =
          value.length === 0
            ? 'First name is required'
            : value.length < 3
            ? 'First name must be at least 3 characters long!'
            : '';
        setName(value);
        break;
      case 'secondName':
        errorStrings.secondName =
          value.length === 0
            ? 'Last name is required'
            : value.length < 3
            ? 'Last name must be at least 3 characters long!'
            : '';
        setSecondName(value);
        break;
      case 'email':
        errorStrings.email = validEmailRegex.test(value)
          ? ''
          : 'Email is not valid!';
        setEmail(value);
        break;
      case 'country':
        errorStrings.country =
          value.length < 3 ? 'Country must be at least 3 characters long!' : '';
        setCountry(value);
        break;
      case 'company':
        errorStrings.company =
          value.length < 3 ? 'Company must be at least 3 characters long!' : '';
        setCompany(value);
        break;
      case 'designation':
        errorStrings.designation =
          value.length < 3
            ? 'Designation must be at least 3 characters long!'
            : '';
        setDesignation(value);
        break;
      case 'message':
        errorStrings.message =
          value.length < 3 ? 'Message must be at least 3 characters long!' : '';
        setMessage(value);
        break;
      default:
        break;
    }

    setErrors(errorStrings);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm(errors)) {
      const data = {
        ProjectID: projectId,
        ContactPersonName: firstName,
        LastName: secondName,
        ContactPersonEmail: email,
        ContactPersonCompany: company,
        ContactPersonDesignation: designation,
        ContactMessage: message,
        ContactUserID: contactUserId,
        CountryID: country,
        IsSubscribed: isSubscribed,
      };
      setProjectContactFormLoader(true);
      apiSauceInstance
        .post(Constants.captureProjectContactDetails, data)
        .then(response => {
          if (response.status) {
            ClearAll();
            closeForm();
            setProjectContactFormLoader(false);
          }
        });
    } else {
      // console.error('Invalid Form')
    }
  };

  const ClearAll = () => {
    setEmail('');
    setMessage('');
    setName('');
    setSecondName('');
    setDesignation('');
    setCompany('');
    setDesignation('');
    setIsSubscribed(false);
    setIsCaptchaPassed(false);
  };

  const onCaptchaChange = value => {
    if (value) {
      setIsCaptchaPassed(true);
    } else {
      setIsCaptchaPassed(false);
    }
  };

  return (
    <div>
      <form onSubmit={e => handleSubmit(e)} noValidate>
        <div className="form-field">
          <div className="input-field">
            <input
              autoComplete="off"
              // auto
              placeholder={t('first_name')}
              type="text"
              name="firstName"
              onChange={e => handleChange(e)}
              noValidate
            />
          </div>
          {errors.firstName.length > 0 && (
            <div className="error-field">{errors.firstName}</div>
          )}
        </div>
        <div className="form-field">
          <div className="input-field">
            <input
              autoComplete="off"
              // auto
              placeholder={t('last_name')}
              type="text"
              name="secondName"
              onChange={e => handleChange(e)}
              noValidate
            />
          </div>
          {errors.secondName.length > 0 && (
            <div className="error-field">{errors.secondName}</div>
          )}
        </div>
        <div className="form-field">
          <div className="input-field">
            <input
              autoComplete="off"
              placeholder={t('email_address')}
              type="email"
              name="email"
              value={email}
              onChange={e => handleChange(e)}
              noValidate
            />
          </div>
          {errors.email.length > 0 && (
            <div className="error-field">{errors.email}</div>
          )}
        </div>

        <div className="form-field">
          <div className="select-field">
            <Select
              name="country"
              value={country}
              onChange={e => handleChange(e)}
              displayEmpty
              disableUnderline
              fullWidth
            >
              <MenuItem value="" disabled>
                {t('countries')}
              </MenuItem>
              {countries.map(country => (
                <MenuItem key={country.countryID} value={country.countryID}>
                  {country.countryName}
                </MenuItem>
              ))}
            </Select>
            {errors.country.length > 0 && (
              <div className="error-field">{errors.country}</div>
            )}
          </div>
        </div>
        <div className="form-field">
          <div className="input-field">
            <input
              autoComplete="off"
              placeholder={t('organization_company')}
              type="company"
              name="company"
              onChange={e => handleChange(e)}
              noValidate
            />
          </div>
          {errors.company.length > 0 && (
            <div className="error-field">{errors.company}</div>
          )}
        </div>
        <div className="form-field">
          <div className="input-field">
            <input
              autoComplete="off"
              placeholder={t('designation')}
              type="designation"
              name="designation"
              onChange={e => handleChange(e)}
              noValidate
            />
          </div>
          {errors.designation.length > 0 && (
            <div className="error-field">{errors.designation}</div>
          )}
        </div>
        <div className="form-field">
          <div className="textarea-field">
            <textarea
              style={{ resize: 'none' }}
              rows="5"
              cols="30"
              placeholder={t('write_message')}
              type="message"
              name="message"
              onChange={e => handleChange(e)}
              noValidate
            />
          </div>
          {errors.message.length > 0 && (
            <div className="error-field">{errors.message}</div>
          )}
        </div>
        <div className="form-field">
          <div className="checkbox-field">
            <Checkbox
              checked={isSubscribed}
              onClick={() => {
                setIsSubscribed(!isSubscribed);
              }}
            />
            <p>{t('subscribe_newsletter')}</p>
          </div>
        </div>
        <div
          className="form-field"
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
          }}
        >
          <ReCAPTCHA
            sitekey={captchaKey}
            size="compact"
            onChange={onCaptchaChange}
          />
        </div>

        <div className="form-action">
          <button
            disabled={
              !validateForm(errors) ||
              !validateFormFields({
                firstName,
                email,
                country,
                company,
                designation,
                message,
              }) ||
              !isCaptchaPassed ||
              projectContactFormLoader
            }
          >
            {projectContactFormLoader ? (
              <div className="three-dots-loader">
                <ThreeDots
                  color="#ffffff"
                  height={60}
                  width={100}
                  timeout={10000}
                />
              </div>
            ) : (
              t('send_message')
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

ChatPopup.propTypes = {
  contactUserId: PropTypes.any,
  projectId: PropTypes.any,
  closeForm: PropTypes.any,
};

export default ChatPopup;
