import React from 'react';
import PropTypes from 'prop-types';

const ProjectStats = ({ title, body, customClassName = '' }) => {
  return (
    <div className={`project-stats-card-wrapper ${customClassName}`}>
      <div className="card">
        <div className="card-header">
          <h3>{title}</h3>
        </div>
        <div className="card-body">
          <p>{body}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectStats;

ProjectStats.propTypes = {
  title: PropTypes.any,
  body: PropTypes.any,
};
